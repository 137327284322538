import React, { useState, useEffect } from 'react';
import {
  fetchUsuarios,
  vincularUsuariosAoCurso,
  desvincularUsuarioDoCurso,
  fetchTodosCursos,
  fetchUsuariosPorCurso,
  updateTipoUsuario,
} from '../services/api';
import {
  Button,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Select,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import './UsuariosAdmin.css';

const UsuariosAdmin: React.FC = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosCurso, setUsuariosCurso] = useState([]);
  const [usuariosNaoCadastrados, setUsuariosNaoCadastrados] = useState([]);
  const [cursoId, setCursoId] = useState<number | null>(null);
  const [cursos, setCursos] = useState([]);
  const [selectedUsuarios, setSelectedUsuarios] = useState<number[]>([]);

  useEffect(() => {
    loadCursos();
    loadUsuarios();
  }, []);

  useEffect(() => {
    if (cursoId) {
      loadUsuariosPorCurso();
    }
  }, [cursoId]);

  const loadUsuarios = async () => {
    const response = await fetchUsuarios();
    setUsuarios(response.data);
  };

  const loadCursos = async () => {
    const response = await fetchTodosCursos();
    setCursos(response.data);
  };

  const loadUsuariosPorCurso = async () => {
    if (cursoId) {
      const response = await fetchUsuariosPorCurso(cursoId);
      setUsuariosCurso(response.data);

      const naoCadastrados = usuarios.filter(
        (usuario) => !response.data.some((u: any) => u.id === usuario.id)
      );
      setUsuariosNaoCadastrados(naoCadastrados);
    }
  };

  const handleVincularUsuarios = async () => {
    if (cursoId && selectedUsuarios.length > 0) {
      await vincularUsuariosAoCurso(cursoId, selectedUsuarios);
      loadUsuariosPorCurso();
      setSelectedUsuarios([]);
    }
  };

  const handleDesvincularUsuario = async (usuarioId: number) => {
    await desvincularUsuarioDoCurso(cursoId, usuarioId);
    loadUsuariosPorCurso();
  };

  const handleSelectUsuario = (usuarioId: number) => {
    setSelectedUsuarios((prevSelected) =>
      prevSelected.includes(usuarioId)
        ? prevSelected.filter((id) => id !== usuarioId)
        : [...prevSelected, usuarioId]
    );
  };

  const handleChangeTipoUsuario = async (usuarioId: number, novoTipo: 'aluno' | 'professor' | 'admin') => {
    if (cursoId) {
      await updateTipoUsuario(cursoId, usuarioId, novoTipo);
      loadUsuariosPorCurso();
    }
  };

  return (
    <div className="usuarios-admin">
      <h3>Vincular Usuários ao Curso</h3>
      <Autocomplete
        options={cursos}
        className='selecao-curso'
        getOptionLabel={(option: any) => option.nome}
        onChange={(event, value) => setCursoId(value ? value.id : null)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Selecione o Curso"
            variant="outlined"
            placeholder="Digite para filtrar"
          />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />

      <hr />

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        <Grid item xs={5}>
          <h4>Usuários Não Cadastrados</h4>
          <List>
            {usuariosNaoCadastrados.map((usuario) => (
              <ListItem
                key={usuario.id}
                button
                onClick={() => handleSelectUsuario(usuario.id)}
                selected={selectedUsuarios.includes(usuario.id)}
              >
                <ListItemText primary={usuario.nome} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={2}>
          <Button
            onClick={handleVincularUsuarios}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!cursoId || selectedUsuarios.length === 0}
          >
            Vincular
          </Button>
        </Grid>

        <Grid item xs={5}>
          <h4>Usuários Cadastrados</h4>
          <List>
            {usuariosCurso.map((usuario) => (
              <ListItem key={usuario.id}>
                <ListItemText primary={usuario.nome} />
                <Select
                  value={usuario.tipoUsuario || 'aluno'}
                  onChange={(e) =>
                    handleChangeTipoUsuario(usuario.id, e.target.value as 'aluno' | 'professor' | 'admin')
                  }
                >
                  <MenuItem value="aluno">Aluno</MenuItem>
                  <MenuItem value="professor">Professor</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDesvincularUsuario(usuario.id)}
                >
                  Desvincular
                </Button>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};

export default UsuariosAdmin;
