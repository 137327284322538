import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Snackbar, Alert } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../services/api'; // Use a função do api.ts

import axios from 'axios';

const AlterarSenhaPorEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [senha, setSenha] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      await resetPassword(token!, senha); // Use a função do api.ts
      setSuccessMessage('Senha redefinida com sucesso.');
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setErrorMessage('Erro ao redefinir a senha. Token expirado ou inválido.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Redefinir Senha
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="senha"
            label="Nova Senha"
            type="password"
            id="senha"
            autoComplete="current-password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleResetPassword}
          >
            Alterar Senha
          </Button>
        </Box>
      </Box>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
        <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AlterarSenhaPorEmail;
