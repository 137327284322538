import React, { useState, useEffect, useRef } from 'react';
import './ImageOcclusion.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck, faRedo, faTrashAlt, faChevronDown, faChevronUp, faCommentDots, faEyeDropper  } from '@fortawesome/free-solid-svg-icons';

interface Occlusion {
  top: number;
  left: number;
  width: number;
  height: number;
  label: string;
}

interface ImageOcclusionProps {
  data: {
    imageUrl: string;
    occlusions: Occlusion[];
    imageWidth: number;
    imageHeight: number;
    comment?:string;
    title?: string;
  } | undefined;
}

const ImageOcclusion: React.FC<ImageOcclusionProps> = ({ data }) => {
  const [answers, setAnswers] = useState<string[]>([]);
  const [revealed, setRevealed] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [results, setResults] = useState<boolean[]>([]);
  const [showOcclusions, setShowOcclusions] = useState(true);
  const [showComment, setShowComment] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number, height: number }>({ width: 800, height: 600 });
  const occlusionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const correctAnswerRefs = useRef<(HTMLSpanElement | null)[]>([]); // Referências para spans das respostas


  useEffect(() => {
    if (data?.occlusions) {
      setAnswers(Array(data.occlusions.length).fill(''));
      setRevealed(false);
      setCorrectCount(0);
      setResults(Array(data.occlusions.length).fill(false));
    }
  }, [data]);

  const updateImageDimensions = () => {
    if (imageRef.current && containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setImageDimensions({
        width: imageRef.current.offsetWidth,
        height: imageRef.current.offsetHeight,
      });
    }
  };

  useEffect(() => {
    updateImageDimensions();
    window.addEventListener('resize', updateImageDimensions);
    return () => {
      window.removeEventListener('resize', updateImageDimensions);
    };
  }, []);

  useEffect(() => {
    if (imageRef.current) {
      const checkIfLoaded = () => {
        if (imageRef.current?.complete && imageRef.current?.naturalHeight !== 0) {
          updateImageDimensions();
        } else {
          setTimeout(checkIfLoaded, 100);
        }
      };
      checkIfLoaded();
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      updateImageDimensions();
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  const scaleFactorWidth = imageDimensions.width / (data?.imageWidth || 1);
  const scaleFactorHeight = imageDimensions.height / (data?.imageHeight || 1);

  const adjustFontSize = (element: HTMLElement, text: string, scaleFactor: number) => {
    let fontSize = 16 * scaleFactor; // Escala o tamanho da fonte inicial
    element.style.fontSize = `${fontSize}px`;

    while (element.scrollWidth > element.offsetWidth || element.scrollHeight > element.offsetHeight) {
      fontSize -= 1;
      element.style.fontSize = `${fontSize}px`;
      if (fontSize <= 10 * scaleFactor) break; // Define um tamanho mínimo de fonte proporcional
    }
  };

  useEffect(() => {
    if (revealed) {
      occlusionRefs.current.forEach((textElement, index) => {
        if (textElement) {
          adjustFontSize(textElement, data?.occlusions[index].label || '', Math.min(scaleFactorWidth, scaleFactorHeight));
        }
      });
    }
  }, [revealed, data?.occlusions, scaleFactorWidth, scaleFactorHeight]);

  // Ajustar o tamanho do texto das respostas ao mostrar respostas
  useEffect(() => {
    if (showAnswers) {
      correctAnswerRefs.current.forEach((spanElement, index) => {
        if (spanElement) {
          const answerText = renderAnswer(data!.occlusions[index]) || '';
          adjustFontSize(spanElement, answerText, Math.min(scaleFactorWidth, scaleFactorHeight));
        }
      });
    }
  }, [showAnswers, data?.occlusions, scaleFactorWidth, scaleFactorHeight]);

  if (!data) {
    return <div>Dados insuficientes para renderizar o componente.</div>;
  }

  const { imageUrl, occlusions, comment } = data;

  const handleChange = (index: number, value: string) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const toggleShowAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const checkAnswers = () => {
    const normalizeString = (str: string | undefined) => {
      return (str || '') // Garante que 'str' não seja undefined
        .normalize("NFD") // Normaliza para decompor caracteres acentuados
        .replace(/[\u0300-\u036f]/g, "") // Remove os acentos
        .replace('*', '') // Remove o asterisco
        .trim() // Remove espaços em branco no início e no fim
        .toLowerCase(); // Converte para minúsculas
    };
    
  
    let count = 0;
    const newResults = occlusions.map((occlusion, index) => {
      let isCorrect = false;
      const answer = answers[index] || '';
  
      if (occlusion.label.includes('|')) {
        const correctOption = occlusion.label
          .split('|')
          .find(option => option.includes('*'))
          ?.replace('*', '')
          .trim()
          .toLowerCase();
  
        isCorrect = normalizeString(answer) === normalizeString(correctOption);
      } else if (occlusion.label && occlusion.label.includes('/')) {
        // Lógica para sinônimos separados por "/"
        const synonyms = occlusion.label.split('/').map(synonym => synonym.trim().toLowerCase());
        isCorrect = synonyms.some(synonym => normalizeString(answer) === normalizeString(synonym));
      } else {
        isCorrect = normalizeString(occlusion.label) === normalizeString(answer);
      }
  
      if (isCorrect) {
        count++;
      }
  
      return isCorrect;
    });
  
    setCorrectCount(count);
    setResults(newResults);
    setRevealed(true);
  };
  
  

  const reset = () => {
    setAnswers(Array(occlusions.length).fill(''));
    setRevealed(false);
    setCorrectCount(0);
    setResults(Array(occlusions.length).fill(false));
    setShowAnswers(false); // Oculta as respostas corretas
    setShowOcclusions(true); // Mostra as oclusões novamente
  };

  const clearIncorrects = () => {
    const newAnswers = answers.map((answer, index) => (results[index] ? answer : ''));
    setAnswers(newAnswers);
    setRevealed(false);
  };

  const toggleOcclusions = () => {
    setShowOcclusions(!showOcclusions);
  };

  const toggleComment = () => {
    setShowComment(!showComment);
  };
  
  const shuffleOptions = (options: string[]) => {
    return options.sort(() => Math.random() - 0.5);
  };

  const renderAnswer = (occlusion: Occlusion) => {
    if (!showAnswers) return null;

    if (occlusion.label.includes('|')) {
      return occlusion.label.split('|').find(opt => opt.includes('*'))?.replace('*', '').trim();
    }
    if (occlusion.label.includes('/')) {
      return occlusion.label.split('/')[0].trim();
    }
    return occlusion.label;
  };
  
  return (
    <div className="image-occlusion-container" ref={containerRef}>
      {data.title && <h3>{data.title}</h3>}
      <div className="image-occlusion-image-container">
        <img ref={imageRef} src={imageUrl} alt="Occlusion" className="image-occlusion-full" />
        {showOcclusions &&
          occlusions.map((occlusion, index) => (
          <div
            key={index}
            className={`image-occlusion-occlusion ${revealed ? (results[index] ? 'correct' : 'incorrect') : ''}`}
            style={{
              top: `${occlusion.top * scaleFactorHeight}px`,
              left: `${occlusion.left * scaleFactorWidth}px`,
              width: `${occlusion.width * scaleFactorWidth}px`,
              height: `${occlusion.height * scaleFactorHeight}px`,
            }}
            ref={(el) => (occlusionRefs.current[index] = el)}
          >
            {!revealed && occlusion.label.includes('|') ? (
              <select
                value={answers[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                className="image-occlusion-input-select"
              >
                <option value="">Select</option>
                {shuffleOptions(occlusion.label.split('|'))
                  .map(option => (
                    <option key={option.trim()} value={option.trim()}>
                      {option.replace('*', '').trim()}
                    </option>
                  ))}
              </select>
            ) : !revealed ? (
              <input
                type="text"
                value={answers[index] || ''}
                onChange={(e) => handleChange(index, e.target.value)}
                className="image-occlusion-input"
              />
            ) : (
              <span className="image-occlusion-text">{answers[index].replace('*', '')}</span>
            )}
            {showAnswers && (
                <span
                  className="image-occlusion-correct-answer"
                  ref={el => (correctAnswerRefs.current[index] = el)}
                >
                  {renderAnswer(occlusion)}
                </span>
              )}
          </div>
          ))}
      </div>
      <div className="image-occlusion-buttons">
        <button onClick={toggleOcclusions} className="image-occlusion-button">
          <FontAwesomeIcon icon={showOcclusions ? faEyeSlash : faEye} />
          <span>{showOcclusions ? 'Mostrar Imagem' : 'Mostrar Occlusions'}</span>
        </button>
        <button onClick={checkAnswers} className="image-occlusion-button">
          <FontAwesomeIcon icon={faCheck} />
          <span>Avaliar</span>
        </button>
        <button onClick={reset} className="image-occlusion-button">
          <FontAwesomeIcon icon={faRedo} />
          <span>Reiniciar</span>
        </button>
        <button onClick={clearIncorrects} className="image-occlusion-button">
          <FontAwesomeIcon icon={faTrashAlt} />
          <span>Limpar Incorretas</span>
        </button>
        <button onClick={toggleShowAnswers} className="image-occlusion-button">
          <FontAwesomeIcon icon={faEyeDropper} />
          <span>{showAnswers ? 'Ocultar Respostas' : 'Mostrar Respostas'}</span>
        </button>
      </div>
      {revealed && <div className="image-occlusion-results">Você acertou {correctCount} de {occlusions.length}</div>}
      {comment &&  comment !== '<p><br></p>' && (
        <>
          <div className="accordion" onClick={toggleComment}>
            <span>{showComment ? 'Ocultar Comentário' : 'Saiba mais'}</span>
            {showComment ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </div>
          {showComment && (
            <div
              className="image-occlusion-comment"
              dangerouslySetInnerHTML={{ __html: comment }} // Renderiza o HTML do comentário
            />
          )}
        </>
      )}
    </div>
  );
  
  
};

export default ImageOcclusion;
