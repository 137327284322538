import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import './TimeLine.css';

interface Phase {
  name: string;
  order: number;
  items: string[];
}

interface TimeLineProps {
  data: {
    title?: string;
    phases: Phase[];
    considerarOrdem?: boolean;
  };
}

const TimeLine: React.FC<TimeLineProps> = ({ data }) => {
  const [phases, setPhases] = useState<Phase[]>([]);
  const [result, setResult] = useState('');

  const shuffle = useCallback((array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }, []);

  useEffect(() => {
    if (data) {
      setPhases(shuffle([...data.phases]));
    }
  }, [data, shuffle]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const reorderedPhases = Array.from(phases);
    const [movedPhase] = reorderedPhases.splice(source.index, 1);
    reorderedPhases.splice(destination.index, 0, movedPhase);

    setPhases(reorderedPhases);
  };

  useEffect(() => {
    if (phases.length > 0) {
      initializePhases();
    }
  }, [phases]);

  const initializePhases = useCallback(() => {
    phases.forEach(phase => {
      const editableDiv = document.querySelector<HTMLDivElement>(`[data-phase="${phase.name}"]`);
      if (editableDiv) {
        // Remove os event listeners existentes antes de adicionar novos
        console.log(`Listeners added to phase: ${phase.name}`);
      }
    });
  }, [phases]);

  const handleClick = (e: MouseEvent) => {
    const div = e.currentTarget as HTMLDivElement;
    const spans = div.querySelectorAll('.timeline-item-label');
    const lastSpan = spans[spans.length - 1];
    if (lastSpan) {
      const range = document.createRange();
      range.setStartAfter(lastSpan);
      range.setEndAfter(lastSpan);
      const br = document.createElement('br');
      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      const selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  };

  const renderItemsAsCorrectAnswer = (items: string[]): string => {
    return items.map(item => `${item}<hr>`).join('');
  };
  

  const tokenizeItems = (content: string): string[] => {
    return content
      .split(/<hr>/) // Divide os itens pelo separador <hr>
      .map(item => item.trim()) // Remove espaços em branco
      .filter(Boolean); // Remove itens vazios
  };
  
  

  const tokenize = (content: string): string[] => {
    // Remove acentos, normaliza espaços e converte para minúsculas
    const normalizeText = (text: string) => {
      return text
        .normalize('NFD') // Decompor caracteres acentuados
        .replace(/[\u0300-\u036f]/g, '') // Remover marcas de acentuação
        .trim() // Remover espaços em branco nas pontas
        .toLowerCase(); // Converter para minúsculas
    };
  
    // Extrai o primeiro item fora das divs e depois divide pelos divs
    const firstItemMatch = content.match(/^(.*?)<div[^>]*>/i);
    const firstItem = firstItemMatch ? firstItemMatch[1] : '';
    const divItems = content.split(/<\/div><div[^>]*>|<div[^>]*>|<\/div>/gi);
  
    // Adiciona o primeiro item no início, se existir
    if (firstItem.trim()) {
      divItems.unshift(firstItem);
    }
  
    // Normaliza e filtra itens vazios
    return divItems
      .map(item => normalizeText(item))
      .filter(Boolean);
  };
  
  const tokenizeComparison = (content: string): string[] => {
    // Divide o conteúdo correto por `<hr>` e normaliza
    return content
      .split(/<hr>/gi)
      .map(item => item.replace(/<\/?[^>]+(>|$)/g, '').trim().toLowerCase())
      .filter(Boolean);
  };
  
  
  const checkOrderWithoutConsiderarOrdem = (): string => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
    let correctItemsCount = 0;
    let totalItemsCount = 0;
  
    inputs.forEach(input => {
      const correctAnswers = new Set(tokenizeComparison(input.dataset.correctAnswer!));
      const userAnswers = tokenizeUserInput(input.innerHTML);
  
      totalItemsCount += correctAnswers.size;
      let newValue = '';
      let correctInThisPhase = 0;
  
      userAnswers.forEach(answer => {
        if (correctAnswers.has(answer)) {
          newValue += `<span style="color:green;">${answer}</span><hr>`;
          correctItemsCount++;
          correctInThisPhase++;
          correctAnswers.delete(answer);
        } else {
          newValue += `<span style="color:red; text-decoration:line-through;">${answer}</span><hr>`;
        }
      });
  
      correctAnswers.forEach(answer => {
        newValue += `<span style="color:orange;">${answer}</span><hr>`;
      });
  
      input.innerHTML = newValue.trim();
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
  
      if (correctInThisPhase === userAnswers.length && correctAnswers.size === 0) {
        input.classList.add('timeline-correct');
        input.classList.remove('timeline-incorrect', 'timeline-partial');
      } else if (correctInThisPhase > 0) {
        input.classList.add('timeline-partial');
        input.classList.remove('timeline-correct', 'timeline-incorrect');
      } else {
        input.classList.add('timeline-incorrect');
        input.classList.remove('timeline-correct', 'timeline-partial');
      }
    });
  
    const itemsScore = (correctItemsCount / totalItemsCount) * 100;
    return `Itens corretos (sem ordem): ${itemsScore.toFixed(2)}%`;
  };
  

  const checkOrderWithConsiderarOrdem = (): string => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
    let correctItemsCount = 0;
    let totalItemsCount = 0;
  
    inputs.forEach((input) => {
      const correctAnswers = tokenizeComparison(input.dataset.correctAnswer!);
      const userAnswers = tokenizeUserInput(input.innerHTML);
  
      totalItemsCount += correctAnswers.length;
      let newValue = '';
      let correctInThisPhase = 0;
  
      correctAnswers.forEach((answer, index) => {
        if (userAnswers[index] === answer) {
          newValue += `<span style="color:green;">${answer}</span><hr>`;
          correctItemsCount++;
          correctInThisPhase++;
        } else if (userAnswers.includes(answer)) {
          newValue += `<span style="color:blue;">${userAnswers[userAnswers.indexOf(answer)]}</span><hr>`;
        } else {
          newValue += `<span style="color:orange;">${answer}</span><hr>`;
        }
      });
  
      userAnswers.forEach((answer) => {
        if (!correctAnswers.includes(answer)) {
          newValue += `<span style="color:red; text-decoration:line-through;">${answer}</span><hr>`;
        }
      });
  
      input.innerHTML = newValue.trim();
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
  
      if (correctInThisPhase === correctAnswers.length) {
        input.classList.add('timeline-correct');
        input.classList.remove('timeline-incorrect', 'timeline-partial');
      } else if (correctInThisPhase > 0) {
        input.classList.add('timeline-partial');
        input.classList.remove('timeline-correct', 'timeline-incorrect');
      } else {
        input.classList.add('timeline-incorrect');
        input.classList.remove('timeline-correct', 'timeline-partial');
      }
    });
  
    const itemsScore = (correctItemsCount / totalItemsCount) * 100;
    return `Itens corretos (com ordem): ${itemsScore.toFixed(2)}%`;
  };
  
  
  const tokenizeComparisonWith = (content: string): string[] => {
    // Tokeniza o conteúdo correto (contém spans)
    return content
      .replace(/<span[^>]*>/g, '') // Remove tags de abertura de span
      .replace(/<\/span>/g, '') // Remove tags de fechamento de span
      .split(/\s+/) // Separa os itens por espaços
      .map(item => item.trim().toLowerCase())
      .filter(Boolean); // Remove itens vazios
  };
  
  const tokenizeUserInput = (content: string): string[] => {
    return content
      .split(/<hr>/gi) // Divide pelos separadores <hr>
      .map(item => item.replace(/<\/?[^>]+(>|$)/g, '').trim().toLowerCase()) // Remove tags HTML e normaliza
      .filter(Boolean); // Remove strings vazias
  };
  
  

  const checkOrder = () => {
    const phasesContainer = document.getElementById("timeline-phases-container");
    const phasesList = phasesContainer?.querySelectorAll<HTMLLIElement>(".timeline-phase-column > li");
  
    // Avaliação das fases
    let correctPhaseCount = 0;
    phasesList?.forEach((item, index) => {
      if (parseInt(item.dataset.order!) === index + 1) {
        item.classList.add("timeline-correct");
        item.classList.remove("timeline-incorrect");
        correctPhaseCount++;
      } else {
        item.classList.add("timeline-incorrect");
        item.classList.remove("timeline-correct");
      }
    });
  
    const phaseScore = (correctPhaseCount / (phasesList?.length || 1)) * 100;
    let resultString = `Fases corretas: ${phaseScore.toFixed(2)}%`;
  
    // Avaliação dos itens (com ou sem ordem)
    if (data.considerarOrdem) {
      resultString += ` | ${checkOrderWithConsiderarOrdem()}`; // Adiciona o resultado dos itens
    } else {
      resultString += ` | ${checkOrderWithoutConsiderarOrdem()}`; // Adiciona o resultado dos itens
    }
  
    setResult(resultString); // Atualiza o resultado final
  };
  
  
  

  const clearIncorrects = () => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
  
    inputs.forEach(input => {
      const spans = input.querySelectorAll<HTMLSpanElement>('span');
      let newValue = '';
  
      spans.forEach(span => {
        // Retém apenas os itens verdes (corretos)
        if (span.style.color === 'green') {
          newValue += `${span.outerHTML}<hr>`;
        }
      });
  
      input.innerHTML = newValue.trim();
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;

  

        input.classList.remove('timeline-partial', 'timeline-incorrect');

    });
    setResult('');
  };
  
  
  
  
  const clearFormat = () => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
    inputs.forEach(input => {
      const spans = input.querySelectorAll<HTMLSpanElement>('span');
      let newValue = '';
      spans.forEach(span => {
        newValue += `<span class="timeline-item-label">${span.innerText}</span><br>`;
      });
      input.innerHTML = newValue.trim();
      input.style.height = 'auto';
      input.style.height = input.scrollHeight + 'px';
      input.style.backgroundColor = 'white';
      if (newValue.trim() === '') {
        input.classList.remove('timeline-incorrect', 'timeline-partial');
      }
    });
  };

  const handleEnterKey = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      document.execCommand('insertHTML', false, '<hr><br>');
    }
  };
  

  const showAnswers = () => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
    inputs.forEach(input => {
      const correctAnswers = tokenizeComparison(input.dataset.correctAnswer!);
      input.innerHTML = correctAnswers.join('<hr>');
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
    });
  };

  const autoResizeDiv = (e: Event) => {
    const target = e.target as HTMLElement;
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  };
  

  useEffect(() => {
    const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
  
    inputs.forEach(input => {
      input.addEventListener('keypress', handleEnterKey as EventListener);
      input.addEventListener('input', autoResizeDiv as EventListener);
    });
  
    return () => {
      inputs.forEach(input => {
        input.removeEventListener('keypress', handleEnterKey as EventListener);
        input.removeEventListener('input', autoResizeDiv as EventListener);
      });
    };
  }, [phases]);
  
  

const clearAll = () => {
  // Limpa os conteúdos e classes das fases
  const inputs = document.querySelectorAll<HTMLDivElement>('.timeline-item-input');
  inputs.forEach(input => {
    input.innerHTML = '';
    input.classList.remove('timeline-correct', 'timeline-incorrect', 'timeline-partial');
    input.style.height = 'auto';
  });

  // Remove as classes de estilo das fases
  const phasesList = document.querySelectorAll<HTMLLIElement>('.timeline-phase-column > li');
  phasesList.forEach(phase => {
    phase.classList.remove('timeline-correct', 'timeline-incorrect');
  });

  // Reseta o resultado e embaralha as fases novamente
  setResult('');
  setPhases(shuffle([...data.phases]));
};


  return (
    <div className="timeline-game">
      {data.title && <h3>{data.title}</h3>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="phases" direction="horizontal">
          {(provided) => (
            <div id="timeline-phases-container" className="timeline-phases-container" ref={provided.innerRef} {...provided.droppableProps}>
              {phases.map((phase, index) => (
                <Draggable key={phase.name} draggableId={`phase-${phase.name}`} index={index}>
                  {(provided) => (
                    <div
                      className="timeline-phase-column"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <li data-order={phase.order} data-name={phase.name}>{phase.name}</li>
                      <div
                        className="timeline-item-input"
                        data-phase={phase.name}
                        data-correct-answer={renderItemsAsCorrectAnswer(phase.items)}
                        contentEditable="true"
                        onClick={handleClick as any}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="actions">
        <button className="timeline-button" onClick={checkOrder}>Avaliar</button>
        <button className="timeline-button" onClick={clearIncorrects}>Limpar Incorretos</button>
        <button className="timeline-button" onClick={showAnswers}>Mostrar Itens</button>
        <button className="timeline-button" onClick={clearAll}>Reiniciar</button>
      </div>
      <div className="timeline-result">{result}</div>
    </div>
  );
};

export default TimeLine;
